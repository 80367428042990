import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./login.scss";
import logo from "../../assets/logo/auditLogo.png";
import { useLoginUserMutation } from "../../redux-toolkit/services/authService";
import { toast } from "react-toastify";
import { alertMessage } from "../../utils/AlertService";
import { setAuthState } from "../../redux-toolkit/slices/authSlice";
import { useDispatch } from "react-redux";
import Loading from "../../components/Loading";
import ForgotResetPassword from "./ForgotResetPassword";
import { PAGE_TITLE } from "../../constant/reportType";
import { Typography } from "antd";

type Props = {};

const { Link: AntdLink } = Typography;

const Login = (props: Props) => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPasswordShow, setForgotPasswordShow] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Mutation
  const [loginUser, loginResponse] = useLoginUserMutation();

  const handleForgotPasswordShow = () => setForgotPasswordShow(true);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    console.log(e)
    if (!email || !password) {
      toast.error("Please enter your login credentials");
    } else {
      await loginUser({
        username: email,
        password: password,
        userRole: "admin",//values.userrole,
      });
    }
  };

  useEffect(() => {
    if (loginResponse.isSuccess) {
      alertMessage("Login successful", "success");
      dispatch(setAuthState(loginResponse.data));
      const localUserObj = {
        token: loginResponse.data.jwtToken,
        userType: loginResponse.data.userRoleName,
        id: loginResponse.data.id,
        username: loginResponse.data.username,
        // userRoleStatus: loginResponse.data.userRoleStatus,
        // permissions: loginResponse.data.permissions,
        // features: loginResponse.data.features,
      };

      // localStorage.setItem("paymentStatus", loginResponse?.data?.paymentStatus ? JSON.stringify(loginResponse?.data?.paymentStatus) : "false");
      localStorage.setItem("localUser", JSON.stringify(localUserObj));
      localStorage.setItem("name", JSON.stringify(loginResponse.data.username));
      const startTime = new Date();
      // saveAuditLog({
      //   payload: {
      //     startTime: startTime.toISOString(),
      //     serviceName: "login",
      //     eventName: "login",
      //     url: "/login",
      //     userId: loginResponse?.data?.id
      //   }
      // })
    } else if (loginResponse.isError) {
      console.log(loginResponse)
      let response: any = loginResponse?.error;
      alertMessage(response.error.data.serviceErrors[0].msg ?
        response?.error?.data?.serviceErrors[0].msg : "Invalid email or password", "error");
      navigate("/login", { replace: true });
    }
  }, [loginResponse.isSuccess]);

  useEffect(() => {
    if (loginResponse.isError) {
      alertMessage("Invalid email or password", "error");
      navigate("/login", { replace: true });
    }
  }, [loginResponse.isError]);

  return (
    <div className="container-fluid">
      <div className="container" style={{ paddingRight: "5rem", paddingLeft: "5rem" }}>
        <div className="row cdvfdfd">
          <div className="col-lg-12 col-md-12 login-box">
            <div className="row">
              <div className="col-lg-6 col-md-6 log-det">
                <div className="small-logo">
                  <img
                    src={logo}
                    alt="Logo"
                    style={{ width: "150px", height: "auto" }}
                  />
                </div>
                <p className="dfmn">
                  Welcome back! Log in to access personalized services and
                  explore everything our platform has to offer.
                </p>

                <form onSubmit={handleSubmit}>
                  {
                    loginResponse.isSuccess ?
                      <Loading /> : ("")
                  }
                  <div className="text-box-cont">
                    <input
                      type="text"
                      className="form-control mb-1"
                      placeholder="Username"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <p className="text-end mx-1">
                      <AntdLink onClick={handleForgotPasswordShow} className="text-danger">
                        Forgot password?
                      </AntdLink>
                    </p>
                    <input
                      type="password"
                      className="form-control "
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />

                    <div className="input-group center">
                      <button
                        className="btn btn-round btn-signup bg-danger text-white"
                        type="submit"
                      >
                        LOG IN
                      </button>
                    </div>
                    <div className="row">
                      <p className="forget-p">
                        Don't have an account?{" "}
                        <Link style={{ color: "#ff3131" }} to="/signup">
                          Sign Up Now
                        </Link>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-lg-6 col-md-6 box-de">
                <div className="inn-cover">
                  <div className="ditk-inf">
                    <div className="small-logo"></div>
                    <h2 className="w-100 text-white">Didn't Have an Account</h2>
                    <p className="text-white">
                      Explore our platform and create your profile today to
                      access exclusive features and personalized services
                      tailored just for you.
                    </p>
                    <Link to="/signup">
                      <button type="button" className="btn btn-outline-light">
                        SIGN UP
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {forgotPasswordShow && (
        <ForgotResetPassword
          forgotResetShow={forgotPasswordShow}
          setForgotResetShow={setForgotPasswordShow}
          title={PAGE_TITLE.FORGOT_PASSWORD}
        />
      )}
    </div>
  );
}

export default Login;
