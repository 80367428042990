import { useEffect, useState } from "react";
import { getLocalStorageData } from "../../utils/LocalStorageService";
import "./home.scss"
import { Table } from "antd";
import { useAppSelector } from "../../redux-toolkit/hooks";
import { allAuditFrameworkDetails, setAllAuditFramework } from "../../redux-toolkit/slices/auditFrameworkSlice";
import { useFindAllAuditFrameworkMutation } from "../../redux-toolkit/services/auditFrameworkService";
import { alertMessage } from "../../utils/AlertService";
import { useDispatch } from "react-redux";
import { useFindAllGroupMasterMutation } from "../../redux-toolkit/services/groupMasterService";
import { useFindAllProcessMasterMutation } from "../../redux-toolkit/services/processMasterService";
import { allGroupMasterDetails, setAllGroupMaster } from "../../redux-toolkit/slices/groupMasterSlice";
import { allProcessMasterDetails, setAllProcessMaster } from "../../redux-toolkit/slices/processMasterSlice";
import { allDimensionMasterDetails, setAllDimensionMaster } from "../../redux-toolkit/slices/dimensionMasterSlice";
import { useFindAllDimensionMasterMutation } from "../../redux-toolkit/services/dimensionMasterService";
import { OVERALL_AUDIT_STATUS, TABLE_COLUMN } from "../../constant/AppConstant";
import { useGetAuditBySearchMutation, useGetAuditsBasedOnUserMutation } from "../../redux-toolkit/services/auditService";
import { fDate } from "../../utils/util";
import { useFindAllOrganizationMasterMutation } from "../../redux-toolkit/services/organizationMasterService";
import { allOrganizationMasterDetails, setAllOrganizationMaster } from "../../redux-toolkit/slices/organizationMasterSlice";
import { getAllAuditBySearch, getAuditsByUserTotalRecords, setAllAuditBySearch, setAuditsByUser } from "../../redux-toolkit/slices/auditSlice";
import { BsFileEarmarkText } from "react-icons/bs";
import ReportDetails from "./ReportDetails";
import { TbStars } from "react-icons/tb";
import AuditRating from "./AuditRating";

type Props = {};

const Home = (props: Props) => {

    const localUserObj = getLocalStorageData("localUser");
    const dispatch = useDispatch();

    //states
    const [dataSource, setDataSource]: any = useState([]);
    const [auditorCompanyName, setAuditorCompanyName]: any = useState("");
    const [currentpage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [columData, setColumnData]: any = useState();
    const [show, setShow] = useState(false);
    const [showRating, setShowRating] = useState(false);
    const [selectedRow, setSelectedRow]: any = useState([]);
    const [auditData, setAuditData]: any = useState();
    const [searchFields, setSearchFields] = useState({
        auditorName: "",
        organizationName: "",
        auditorCompany: "",
        auditeeName: "",
        auditorEmail: "",
        auditFrameworkName: "",
        groupMaster: "",
        dimension: "",
        process: "",
        stakeholder: "",
        auditType: "",
        auditStatus: "",
    });

    // const [searchFields, setSearchFields] = useState([]);


    //getData
    const [getAllCompanies, allCompaniesResponse] = useFindAllOrganizationMasterMutation();
    const [findAllAuditFramework, findAllAuditFrameworkResponse] = useFindAllAuditFrameworkMutation();
    const [findAllGroupMaster, findAllGroupMasterResponse] = useFindAllGroupMasterMutation();
    const [findAllProcessMaster, findAllProcessMasterResponse] = useFindAllProcessMasterMutation();
    const [findAllDimensionMaster, findAllDimensionMasterResponse] = useFindAllDimensionMasterMutation();
    const [getAuditsByUser, auditsByUserResponse] = useGetAuditsBasedOnUserMutation();
    const [getAuditsBySearch, auditsBySearchResponse] = useGetAuditBySearchMutation();

    //selector
    const organizationData: any = useAppSelector(allOrganizationMasterDetails);
    const auditFrameworkData: any = useAppSelector(allAuditFrameworkDetails);
    const allGroupMasterData: any = useAppSelector(allGroupMasterDetails);
    const allProcessMasters: any = useAppSelector(allProcessMasterDetails);
    const allDimensionMasters: any = useAppSelector(allDimensionMasterDetails);
    const getAuditsByUserTotal: any = useAppSelector(getAuditsByUserTotalRecords);

    useEffect(() => {
        // getAllCompanies({
        //     token: localUserObj?.token,
        // });
        // findAllAuditFramework({
        //     token: localUserObj?.token,
        // });
        // findAllDimensionMaster({
        //     token: localUserObj?.token,
        // });
        // findAllProcessMaster({
        //     token: localUserObj?.token,
        // });
        // findAllGroupMaster({
        //     token: localUserObj?.token,
        // });
        getAuditsByUser({
            token: localUserObj.token,
            payload: {
                size: pageSize,
                page: currentpage - 1,
                orderBy: "asc",
                userId: localUserObj.id
            }
        })
    }, [])

    const paginationChange = (page: number, pageSize: number) => {
        getAuditsByUser({
            token: localUserObj.token,
            payload: {
                size: pageSize,
                page: page - 1,
                orderBy: "asc",
                userId: localUserObj.id
            }
        });
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const handleChange = (e: any) => {

        let { name, value } = e.target;

        if (name === "organizationName") {
            const org = organizationData && organizationData
                .find((item: any) => {
                    return parseInt(item.id) === parseInt(value);
                });

            value = org.name
        } else if (name === "auditFrameworkName") {
            const auditFramework = auditFrameworkData && auditFrameworkData
                .find((item: any) => {
                    return parseInt(item.id) === parseInt(value);
                });

            value = auditFramework.name
        }

        setSearchFields((prevFields: any) => {
            const updatedFields = {
                ...prevFields,
                [name]: value,  // Update the relevant search field (e.g., 'auditorName', 'organizationName')
            };

            // Call handleSearch with the updated searchFields
            setTimeout(() => {
                handleSearch(updatedFields);  // Pass the updatedFields to handleSearch
            }, 1000);

            // Return the updated fields to update the state
            return updatedFields;
        });

        // Update searchFields
        // const updatedFields: any = searchFields.map((field: any) => field.name === name ? { name, value } : field);
        // if (!updatedFields.some((field: any) => field.name === name)) {
        //     updatedFields.push({ name, value });
        // }

        // setSearchFields(updatedFields)

        // setTimeout(() => {
        //     handleSearch(updatedFields)
        // }, 300);
    }


    const handleSearch = (searchPayload: any) => {

        getAuditsBySearch({
            token: localUserObj.token,
            payload: { offset: 100, pageNumber: 0, searchBy: searchPayload },
        });
    }

    const handleOpenReport = (record: any) => {
        setSelectedRow(record);
        // setAuditData()
        setShow(true);
    };

    const handleOpenRating = (record: any) => {
        setSelectedRow(record);
        // setAuditData()
        setShowRating(true);
    };

    useEffect(() => {
        const tempColumn: any = [];
        const tempAuditReport: any = {
            title: "Audit Report",
            dataIndex: "auditReport",
            key: "auditReport",
            fixed: "right",
            render: (index: any, record: any) => {
                return (
                    // for delete switch button
                    <div className="d-flex justify-content-between">
                        <div
                            className="px-2 pe-auto"
                            onClick={() => {
                                handleOpenReport(record);
                            }}
                        >
                            <BsFileEarmarkText className="text-danger" size={18} />
                        </div>
                    </div>
                );
            },
        };

        const tempRating: any = {
            title: "Rating",
            dataIndex: "rating",
            key: "rating",
            fixed: "right",
            render: (index: any, record: any) => {
                return (
                    // for delete switch button
                    <div className="d-flex justify-content-between">
                        <div
                            className="px-2 pe-auto"
                            onClick={() => {
                                handleOpenRating(record);
                            }}
                        >
                            <TbStars className="text-danger" size={18} />
                        </div>
                    </div>
                );
            },
        };

        TABLE_COLUMN.map((item: any) => {
            tempColumn.push(item);
        });
        tempColumn.push(tempAuditReport);
        tempColumn.push(tempRating);
        setColumnData(tempColumn);
    }, []);

    useEffect(() => {
        if (allCompaniesResponse.isSuccess) {
            dispatch(setAllOrganizationMaster(allCompaniesResponse));
        } else if (allCompaniesResponse.isError) {
            alertMessage("Error fetching companies", "error");
        }
    }, [allCompaniesResponse.isSuccess, allCompaniesResponse.isError]);

    useEffect(() => {
        if (findAllAuditFrameworkResponse.isSuccess) {
            dispatch(setAllAuditFramework(findAllAuditFrameworkResponse));
        } else if (findAllAuditFrameworkResponse.isError) {
            alertMessage("Error while fetching audit framewrok", "error");
        }
    }, [findAllAuditFrameworkResponse.isSuccess, findAllAuditFrameworkResponse.isError]);

    useEffect(() => {
        if (findAllGroupMasterResponse.isSuccess) {
            dispatch(setAllGroupMaster(findAllGroupMasterResponse))
        } else if (findAllGroupMasterResponse.isError) {
            alertMessage("Error while fetchin group master", "error");
        }
    }, [findAllGroupMasterResponse.isSuccess, findAllGroupMasterResponse.isError]);

    useEffect(() => {
        if (findAllDimensionMasterResponse.isSuccess) {
            dispatch(setAllDimensionMaster(findAllDimensionMasterResponse))
        } else if (findAllDimensionMasterResponse.isError) {
            alertMessage("Error while fetchin dimension masters", "error");
        }
    }, [findAllDimensionMasterResponse.isSuccess, findAllDimensionMasterResponse.isError]);

    useEffect(() => {
        if (findAllProcessMasterResponse.isSuccess) {
            dispatch(setAllProcessMaster(findAllProcessMasterResponse))
        } else if (findAllProcessMasterResponse.isError) {
            alertMessage("Error while fetchin process masters", "error");
        }
    }, [findAllProcessMasterResponse.isSuccess, findAllProcessMasterResponse.isError]);

    useEffect(() => {
        if (auditsByUserResponse.isSuccess) {
            dispatch(setAuditsByUser(auditsByUserResponse))
            setAuditData(auditsByUserResponse.data.data)
            const dataSource = transformData(auditsByUserResponse.data.data);
            setDataSource(dataSource)
            console.log(dataSource)
        } else if (auditsByUserResponse.isError) {
            alertMessage("Error while fetchin audits data", "error");
        }
    }, [auditsByUserResponse.isSuccess, auditsByUserResponse.isError]);

    useEffect(() => {
        if (auditsBySearchResponse.isSuccess) {
            dispatch(setAllAuditBySearch(auditsBySearchResponse));
            const dataSource = transformData(auditsBySearchResponse.data.data);
            setDataSource(dataSource);
        } else if (auditsBySearchResponse.isError) {
            alertMessage("Error while searching", "error");
        }
    }, [auditsBySearchResponse.isSuccess, auditsBySearchResponse.isError]);


    function transformData(rawData: any) {
        return rawData.map((item: any) => {
            // Extract relevant fields
            const {
                id,
                auditorCompanyName,
                auditorName,
                organizationName,
                createdDate,
                validityPeriodInDays,
                auditReport,
            } = item;

            // Calculate Next Audit Date and Expiry Date
            const nextAuditDate = calculateNextAuditDate(createdDate, validityPeriodInDays !== null ? parseInt(validityPeriodInDays) : 0);
            const expiryDate = calculateExpiryDate(createdDate, validityPeriodInDays !== null ? parseInt(validityPeriodInDays) : 0);

            return {
                auditId: id,
                auditorCompanyName: auditorCompanyName || "-",
                auditorName: auditorName || "-",
                organizationName: organizationName || "-",
                createdDate: new Date(createdDate).toISOString().split('T')[0],
                nextAuditDate,
                validityPeriodInDays,
                expiryDate,
                auditReport: auditReport || "-",
            };
        });
    }

    function calculateNextAuditDate(createdDate: any, validityPeriodInDays: any) {
        const date = new Date(createdDate);
        date.setDate(date.getDate() + validityPeriodInDays);
        // return date.toISOString().split('T')[0];
        return fDate(new Date(date));
    }

    function calculateExpiryDate(createdDate: any, validityPeriodInDays: any) {
        const date = new Date(createdDate);
        // If validityPeriodInDays is 0, expiry date will be the same as createdDate
        date.setDate(date.getDate() + (validityPeriodInDays > 0 ? validityPeriodInDays - 1 : 0));
        return fDate(new Date(date));
    }


    return (
        <div>
            <div className="fw-bold fs-4 mt-2 text-center">
                Welcome to Passion Framework Audit
            </div>

            <div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="mt-3">
                            <div className="d-flex">
                                <div>Auditor Name</div>
                            </div>
                            <input
                                className="form-control form-control-sm w-100"
                                type="text"
                                aria-label=".form-control-sm example"
                                onChange={handleChange}
                                // value={values.auditorName}
                                name="auditorName"
                            // required
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="w-100 mt-3">
                            <div className="d-flex">
                                <div>Organization</div>
                            </div>
                            <div className="pe-2">
                                <select
                                    className="form-select form-select-sm w-100"
                                    aria-label=".form-select-sm example"
                                    onChange={handleChange}
                                    // value={values.organizationId}
                                    name="organizationName"
                                >
                                    <option value="0">Select Organization</option>
                                    {organizationData && organizationData.map((itemType: any, index: any) => {
                                        return (
                                            <option
                                                key={index.toString()}
                                                value={itemType.id}
                                            >
                                                {itemType.id} - {itemType.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mt-3">
                            <div className="d-flex">
                                <div>Auditor Company</div>
                            </div>
                            <input
                                className="form-control form-control-sm w-100"
                                type="text"
                                aria-label=".form-control-sm example"
                                onChange={handleChange}
                                // value={auditorCompanyName}
                                name="auditorCompany"
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="mt-3">
                            <div className="d-flex">
                                <div>Auditee Name</div>
                            </div>
                            <input
                                className="form-control form-control-sm w-100"
                                type="text"
                                aria-label=".form-control-sm example"
                                onChange={handleChange}
                                // value={values.auditorName}
                                name="auditeeName"
                            // required
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mt-3">
                            <div className="d-flex">
                                <div>Auditor Email</div>
                            </div>
                            <input
                                className="form-control form-control-sm w-100"
                                type="text"
                                aria-label=".form-control-sm example"
                                onChange={handleChange}
                                // value={auditorEmail}
                                name="auditorEmail"
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="w-100 mt-3">
                            <div className="d-flex">
                                <div>Audit Framework</div>
                            </div>
                            <div className="pe-3">
                                <select
                                    className="form-select form-select-sm w-100"
                                    aria-label=".form-select-sm"
                                    onChange={handleChange}
                                    // onChange={(e: any) => {
                                    //     handleChange(e);
                                    //     console.log(e)
                                    //     handleAuditFrameworkChange(e.target.value);
                                    //     setAuditFramework(e.target.value)
                                    // }}
                                    name="auditFrameworkName"
                                // value={values.auditFramework}
                                >
                                    <option value="0">
                                        Select audit framework
                                    </option>
                                    {auditFrameworkData && auditFrameworkData.map((framework: any, index: any) => (
                                        <option key={index.toString()} value={framework.id}>
                                            {framework.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-md-4">
                        <div className="w-100 mt-3">
                            <div className="d-flex">
                                <div>Group Master</div>
                            </div>
                            <div className="pe-3">
                                <select
                                    className="form-select form-select-sm w-100"
                                    aria-label=".form-select-sm example"
                                    onChange={handleChange}
                                    name="groupMaster"
                                // value={values.groupMaster}
                                >
                                    <option value="">
                                        Select Group
                                    </option>
                                    {allGroupMasterData &&
                                        allGroupMasterData.map((itemType: any, index: any) => {
                                            return (
                                                <option
                                                    key={index.toString()}
                                                    value={itemType.id}
                                                >
                                                    {itemType.id} - {itemType.name}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="w-100 mt-3">
                            <div className="d-flex">
                                <div>Dimension</div>
                            </div>
                            <div className="pe-3">
                                <select
                                    className="form-select form-select-sm w-100"
                                    aria-label=".form-select-sm example"
                                    onChange={handleChange}
                                    name="dimension"
                                // value={values.dimension}
                                >
                                    <option value="">
                                        Select Dimension
                                    </option>
                                    {allDimensionMasters &&
                                        allDimensionMasters.map((itemType: any, index: any) => {
                                            return (
                                                <option
                                                    key={index.toString()}
                                                    value={itemType.id}
                                                >
                                                    {itemType.id} - {itemType.name}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="w-100 mt-3">
                            <div className="d-flex">
                                <div>Process</div>
                            </div>
                            <div className="pe-3">
                                <select
                                    className="form-select form-select-sm w-100"
                                    aria-label=".form-select-sm example"
                                    onChange={handleChange}
                                    name="process"
                                // value={values.process}
                                >
                                    <option value="">
                                        Select Process
                                    </option>
                                    {allProcessMasters &&
                                        allProcessMasters.map((itemType: any, index: any) => {
                                            return (
                                                <option
                                                    key={index.toString()}
                                                    value={itemType.id}
                                                >
                                                    {itemType.id} - {itemType.name}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-md-4">
                        <div className="w-100 mt-3">
                            <div className="d-flex">
                                <div>Audit Type</div>
                            </div>
                            <div className="pe-3">
                                <input
                                    className="form-control form-control-sm w-100"
                                    type="text"
                                    aria-label=".form-control-sm example"
                                    onChange={handleChange}
                                    // value={values.auditorName}
                                    name="auditType"
                                // required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="w-100 mt-3">
                            <div className="d-flex">
                                <div>Stakeholder</div>
                            </div>
                            <div className="pe-3">
                                <input
                                    className="form-control form-control-sm w-100"
                                    type="text"
                                    aria-label=".form-control-sm example"
                                    onChange={handleChange}
                                    // value={values.auditorName}
                                    name="stakeholder"
                                // required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="w-100 mt-3">
                            <div className="d-flex">
                                <div>Audit Status</div>
                            </div>
                            <div className="pe-3">
                                <select
                                    className="form-select form-select-sm w-100"
                                    aria-label=".form-select-sm example"
                                    name="auditStatus"
                                    onChange={handleChange}
                                // value={auditStatusMap.find((entry: any) => entry.recordId === record.id)?.auditStatus || ""}
                                // onChange={(e) => handleAuditStatusChange(record, e.target.value)}
                                >
                                    <option value="">Select status</option>
                                    {OVERALL_AUDIT_STATUS.map((itemType: any) => {
                                        return (
                                            <option
                                                key={itemType.id}
                                                value={itemType.name}
                                            >
                                                {itemType.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-3">
                <Table
                    scroll={{ x: "max-content" }}
                    dataSource={dataSource}
                    columns={columData}
                    pagination={{
                        total: getAuditsByUserTotal !== null ? getAuditsByUserTotal : dataSource.length,
                        hideOnSinglePage: true,
                        responsive: true,
                        current: currentpage,
                        // showSizeChanger: true,  
                        pageSizeOptions: [10, 20, 30],
                        onChange: (page, pageSize) => {
                            paginationChange(page, pageSize);
                        },
                        pageSize: pageSize,
                    }}
                // rowKey={(record) => `${record.dimensionId}_${record.processId}`}
                />
            </div>
            {show && (
                <ReportDetails
                    show={show}
                    setShow={setShow}
                    data={selectedRow}
                    auditData={auditData}
                />
            )}
            {showRating && (
                <AuditRating
                    show={showRating}
                    setShow={setShowRating}
                    data={selectedRow}
                    auditData={auditData}
                />
            )}
        </div>
    );
};

export default Home;