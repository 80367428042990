import { AiOutlineAudit, AiOutlineHome } from "react-icons/ai";
import { TbDimensions, TbStars } from "react-icons/tb";
import { BsBuildings, BsCollection, BsList, BsPeople, BsStars } from "react-icons/bs";
import { FaUsersGear } from "react-icons/fa6";
import { SiFramework } from "react-icons/si";

export const SidebarData = [
  {
    title: "Home",
    path: "/home",
    icon: <AiOutlineHome />,
    cName: "nav-text text-left",
  },
  {
    title: "Audit Rating Report",
    path: "/raiting",
    icon: <BsStars />,
    cName: "nav-text text-left",
  },
  {
    title: "User Masters",
    path: "#",
    icon: <BsList />,
    cName: "sub-nav-text",
    subNav: [
      {
        title: "User",
        path: "/usersmaster/userMaster",
        icon: <BsPeople />,
        cName: "sub-nav-text",
      },
      {
        title: "Organization",
        path: "/master/organizationMaster",
        icon: <BsBuildings />,
        cName: "sub-nav-text",
      },
      {
        title: "User Role",
        path: "/usersmaster/userRole",
        icon: <BsBuildings />,
        cName: "sub-nav-text",
      },
      {
        title: "User Group",
        path: "/usersmaster/userGroup",
        icon: <BsCollection />,
        cName: "sub-nav-text",
      },
    ],
  },
  {
    title: "Master",
    path: "#",
    icon: <BsList />,
    cName: "sub-nav-text",
    subNav: [
      // {
      //   title: "Organization Master",
      //   path: "/master/organizationMaster",
      //   icon: <BsBuildings />,
      //   cName: "sub-nav-text",
      // },
      {
        title: "Group Master",
        path: "/master/groupMaster",
        icon: <BsCollection />,
        cName: "sub-nav-text",
      },
      {
        title: "Dimension Master",
        path: "/master/dimensionMaster",
        icon: <TbDimensions />,
        cName: "sub-nav-text",
      },
      {
        title: "Process Master",
        path: "/master/processMaster",
        icon: <FaUsersGear />,
        cName: "sub-nav-text",
      },
      {
        title: "Audit Framework",
        path: "/master/auditFramework",
        icon: <SiFramework />,
        cName: "sub-nav-text",
      },
      {
        title: "Audit",
        path: "/master/audit",
        icon: <AiOutlineAudit />,
        cName: "sub-nav-text",
      },
    ],
  },
];