import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface OrganizationMasterState {
  data: [] | null | any;
  currentPage: number | null;
  totalRecords: number | null;
  totalPages: number | null;
  allOrganizationMaster: [] | null | any;
}
const initialState: OrganizationMasterState = {
  data: null,
  totalRecords: null,
  totalPages: null,
  currentPage: null,
  allOrganizationMaster: null
};
export const organizationMasterSlice = createSlice({
  name: 'OrganizationMaster',
  initialState,
  reducers: {
    setAllOrganizationMaster: (state, action) => {
      state.allOrganizationMaster = action.payload.data;
    },
    setOrganizationMaster: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    setAllOrganizationMasterBySearch: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setAllOrganizationMaster, setOrganizationMaster, setAllOrganizationMasterBySearch,reset } = organizationMasterSlice.actions;
export const allOrganizationMasterDetails = (state: RootState) => state.organizationMaster.allOrganizationMaster;
export const getAllOrganizationMasterDetails = (state: RootState) => state.organizationMaster.data;
export const getOrganizationMasterTotalRecords=(state:RootState)=>state.organizationMaster.totalRecords;
export const getAllOrganizationMasterBySearch = (state: RootState) => state.organizationMaster.data;
export default organizationMasterSlice.reducer;