import { fDate } from "../utils/util";

export const DIMENSION_MASTER_COLUMN = [
    {
        dataIndex: "id",
        title: "Dimension Master Id",
    },
    {
        dataIndex: "name",
        title: "Dimension Master Name",
    },
    {
        dataIndex: "description",
        title: "Dimension Master Description",
    },
];

export const PROCESS_MASTER_COLUMN = [
    {
        dataIndex: "id",
        title: "Process Master Id",
    },
    {
        dataIndex: "name",
        title: "Process Master Name",
    },
    {
        dataIndex: "description",
        title: "Process Master Description",
    },
];

export const AUDIT_FRAMEWORK_COLUMN = [
    {
        dataIndex: "id",
        title: "Id",
        width: 20,
    },
    {
        dataIndex: "name",
        title: "Name",
        width: 250,
    },
    {
        dataIndex: "dimensionName",
        title: "Dimension Name",
        width: 70,
    },
    {
        dataIndex: "processName",
        title: "Process Name",
        width: 120,
    },
    {
        dataIndex: "expectedEvidence",
        title: "Expected Evidence",
        width: 120,
    },
    {
        dataIndex: "stakeholder",
        title: "Stakeholder",
        width: 80,
    },
    {
        dataIndex: "costPerAuditAnnum",
        title: "Cost Per Audit Annum",
        width: 50,
    },
    {
        dataIndex: "currency",
        title: "Currency",
        width: 50,
    },
    {
        dataIndex: "frequencyYear",
        title: "Frequency Year",
        width: 50,
    },
    {
        dataIndex: "percentageAuditor",
        title: "Percentage Auditor",
        width: 50,
    },
    {
        dataIndex: "percentageImprovement",
        title: "Percentage Improvement",
        width: 50,
    },
    {
        dataIndex: "percentagePCombinator",
        title: "Percentage PCombinator",
        width: 50,
    },
];

export const AUDIT_COLUMN = [
    {
        dataIndex: "id",
        title: "Id",
    },
    {
        dataIndex: "name",
        title: "Name",
    },
    {
        dataIndex: "auditCode",
        title: "Audit Code",
    },
    {
        dataIndex: "auditType",
        title: "Audit Type",
    },
    // {
    //     dataIndex: "scopeOfAudit",
    //     title: "Scope Of Audit",
    // },
    {
        dataIndex: "organizationName",
        title: "Organization Name",
    },
    {
        dataIndex: "auditorName",
        title: "Auditor Name",
    },
    {
        dataIndex: "auditorCompanyName",
        title: "Auditor Company Name",
    },
    {
        dataIndex: "auditorPhone",
        title: "Auditor Phone",
    },
    {
        dataIndex: "auditorEmail",
        title: "Auditor Email",
    },
    {
        dataIndex: "auditFrameworkName",
        title: "Audit Framework Name",
    },
    // {
    //     dataIndex: "dimensionName",
    //     title: "Dimension Name",
    // },
    // {
    //     dataIndex: "processName",
    //     title: "Process Name",
    // },
    // {
    //     dataIndex: "expectedEvidence",
    //     title: "Expected Evidence",
    // },
    // {
    //     dataIndex: "stakeholder",
    //     title: "Stakeholder",
    // },
    {
        dataIndex: "actualDescription",
        title: "Actual Description",
    },
    {
        dataIndex: "evidenceUrl",
        title: "Evidence Url",
    },
    // {
    //     dataIndex: "overallRemark",
    //     title: "Overall Remark",
    // },
    {
        dataIndex: "overallStatus",
        title: "Overall Status",
    },
    {
        dataIndex: "dependentOrgCode",
        title: "Dependent Organization Code",
    },
];

export const GROUP_MASTER_COLUMN = [
    {
        dataIndex: "id",
        title: "Group Master Id",
    },
    {
        dataIndex: "name",
        title: "Group Master Name",
    },
    {
        dataIndex: "description",
        title: "Group Master Description",
    },
];

export const ORGANIZATION_MASTER_COLUMN = [
    {
        dataIndex: "id",
        title: "Organization Id",
    },
    {
        dataIndex: "name",
        title: "Organization Name",
    },
    {
        dataIndex: "code",
        title: "Organization Code",
    },
    {
        dataIndex: "contactPerson",
        title: "Contact Person",
    },
    {
        dataIndex: "contactEmail",
        title: "Contact Email",
    },
    {
        dataIndex: "contactPhone",
        title: "Contact Phone",
    },
    {
        dataIndex: "website",
        title: "Website",
    },
    {
        dataIndex: "city",
        title: "City",
    },
    {
        dataIndex: "state",
        title: "State",
    },
    {
        dataIndex: "country",
        title: "Country",
    },
    {
        dataIndex: "dependentOrgCode",
        title: "Dependent Organization Code",
    },
    {
        dataIndex: "organizationType",
        title: "Organization Type",
    },
];

export const AUDIT_STATUS = [
    {
        id: 0,
        name: "Compliant",
    },
    {
        id: 1,
        name: "Non-Compliant",
    },
    {
        id: 2,
        name: "Minor Non-Compliant",
    },
    {
        id: 3,
        name: "Observations",
    },
    {
        id: 4,
        name: "Suggestions",
    },
    {
        id: 5,
        name: "Under Review",
    },
]

export const OVERALL_AUDIT_STATUS = [
    {
        id: 0,
        name: "Fully compliant",
    },
    {
        id: 1,
        name: "Major non compliant",
    },
    {
        id: 2,
        name: "Minor non compliant",
    },
    {
        id: 3,
        name: "Observations",
    },
    {
        id: 4,
        name: "Suggestions",
    },
    {
        id: 5,
        name: "Not applicable",
    },
    {
        id: 6,
        name: "On hold",
    },
]

export const ASSESSMENT_RATINGS = [
    {
        id: 0,
        value: 0.0,
        name: "0.0 - Un-organized",
        desc: "No formal processes in place; chaotic or ad-hoc actions.",
    },
    {
        id: 1,
        value: 0.5,
        name: "0.5 - Initial",
        desc: "Very minimal, informal processes that are reactive in nature.",
    },
    {
        id: 2,
        value: 1.0,
        name: "1.0 - Managed",
        desc: "Basic processes are defined and documented, but inconsistently applied.",
    },
    {
        id: 3,
        value: 1.5,
        name: "1.5 - Repeatable",
        desc: "Some repeatable processes exist, but improvements are not systematically applied.",
    },
    {
        id: 4,
        value: 2.0,
        name: "2.0 - Defined",
        desc: "Standardized processes are defined, documented, and implemented consistently.",
    },
    {
        id: 5,
        value: 2.5,
        name: "2.5 - Monitored",
        desc: "Processes are measured and monitored, though not fully controlled.",
    },
    {
        id: 6,
        value: 3.0,
        name: "3.0 - Standardized",
        desc: "Fully documented and standardized processes exist across the organization.",
    },
    {
        id: 7,
        value: 3.5,
        name: "3.5 - Controlled",
        desc: "Processes are well-controlled, with performance regularly evaluated.",
    },
    {
        id: 8,
        value: 4.0,
        name: "4.0 - Predictable",
        desc: "Processes are quantitatively managed, and outcomes are predictable.",
    },
    {
        id: 9,
        value: 4.5,
        name: "4.5 - Improving",
        desc: "Continuous process improvement is in place with regular reviews.",
    },
    {
        id: 10,
        value: 5.0,
        name: "5.0 - Optimized",
        desc: "Processes are fully optimized for efficiency and effectiveness, with innovation and continuous improvement.",
    },
];

export const AUDIT_RATINGS = [
    {
        id: 0,
        value: 0.0,
        name: "0.0 - Non-Compliant / Un-Organized",
        desc: "No formal quality management system (QMS) exists, and there is no adherence to standards.",
    },
    {
        id: 1,
        value: 0.5,
        name: "0.5 - Minimal Compliance",
        desc: "Some elements of a QMS are present but not documented; major non-conformities to standards exist.",
    },
    {
        id: 2,
        value: 1.0,
        name: "1.0 - Initial Implementation",
        desc: "Basic QMS processes are in place but not consistently followed; several non-conformities to requirements.",
    },
    {
        id: 3,
        value: 1.5,
        name: "1.5 - Partial Compliance",
        desc: "Some processes are documented and implemented, but inconsistencies or gaps exist in adherence to standards.",
    },
    {
        id: 4,
        value: 2.0,
        name: "2.0 - Basic Compliance",
        desc: "Most processes meet our requirements, but there may be minor non-conformities or areas for improvement.",
    },
    {
        id: 5,
        value: 2.5,
        name: "2.5 - Developing Compliance",
        desc: "The organization has implemented a QMS with consistent process adherence, though not fully integrated into all departments.",
    },
    {
        id: 6,
        value: 3.0,
        name: "3.0 - Compliant",
        desc: "The organization meets all our standards with documented, consistently followed processes; minor opportunities for improvement may exist.",
    },
    {
        id: 7,
        value: 3.5,
        name: "3.5 - Improving Compliance",
        desc: "The organization goes beyond basic compliance by actively addressing improvement opportunities and implementing corrective actions.",
    },
    {
        id: 8,
        value: 4.0,
        name: "4.0 - Proactive Compliance",
        desc: "The organization has a proactive approach to quality management, regularly reviewing and improving processes.",
    },
    {
        id: 9,
        value: 4.5,
        name: "4.5 - Continuous Improvement",
        desc: "A well-established QMS with continuous monitoring, regular audits, and active quality improvement programs.",
    },
    {
        id: 10,
        value: 5.0,
        name: "5.0 - Optimized QMS / Best Practice",
        desc: "The organization operates at a world-class level, fully compliant with standards, with an optimized QMS focused on continual improvement, innovation, and customer satisfaction.",
    },
];

export const TABLE_COLUMN = [
    {
        title: "Audit Id",
        dataIndex: "id",
        key: "id",
        hidden: true
    },
    {
        title: "Auditor Company",
        dataIndex: "auditorCompanyName",
        key: "auditorCompanyName",
    },
    {
        title: "Auditor",
        dataIndex: "auditorName",
        key: "auditorName",
    },
    {
        title: "Organization",
        dataIndex: "organizationName",
        key: "organizationName",
    },
    {
        title: "Audit Date",
        dataIndex: "createdDate",
        key: "createdDate",
        render: (index: any, record: any) => {
            let formattedDate;
            if (record.createdDate) {
                const inputDate = new Date(record?.createdDate);
                formattedDate = fDate(inputDate);
            } else {
                formattedDate = "-"
            }
            return (
                <div className="d-flex align-items-start">
                    {formattedDate}
                </div>
            );
        },
    },
    {
        title: "Next Audit Date",
        dataIndex: "nextAuditDate",
        key: "nextAuditDate",
    },
    {
        title: "Validity days",
        dataIndex: "validityPeriodInDays",
        key: "validityPeriodInDays",
    },
    {
        title: " Expiry Date",
        dataIndex: "expiryDate",
        key: "expiryDate",
    },
    // {
    //     title: "Audit Report",
    //     dataIndex: "auditReport",
    //     key: "auditReport",
    // },
]

export const TABLE_COLUMN_REPORT_DETAILS = [
    {
        title: "Id",
        dataIndex: "id",
        key: "id",
        hidden: true,
    },
    {
        title: "Group Master Name",
        dataIndex: "groupMasterName",
        key: "groupMasterName",
    },
    {
        title: "Dimension Name",
        dataIndex: "dimensionName",
        key: "dimensionName",
    },
    {
        title: "Process Name",
        dataIndex: "processName",
        key: "processName",
    },
    {
        title: "Expected Evidence",
        dataIndex: "expectedEvidence",
        key: "expectedEvidence",
    },
    {
        title: "Stakeholder",
        dataIndex: "stakeholder",
        key: "stakeholder",
    },
    {
        title: "Audit Status",
        dataIndex: "auditStatus",
        key: "auditStatus",
    },
    {
        title: "Stakeholder",
        dataIndex: "stakeholder",
        key: "stakeholder",
    },
    {
        title: "Remark",
        dataIndex: "remark",
        key: "remark",
    },
    {
        title: "Evidence",
        dataIndex: "evidence",
        key: "evidence",
    },
];

export const USER_COLUMN = [
    {
        key: "id",
        dataIndex: "id",
        title: "User Id",
    },
    {
        key: "name",
        dataIndex: "name",
        title: "Name",
    },
    {
        key: "mobileNo",
        dataIndex: "phoneNumber",
        title: "Mobile Number",
    },
    {
        key: "email",
        dataIndex: "email",
        title: "Email",
    },
    {
        key: "role",
        dataIndex: "userRole",
        title: "Subscriber",
    },
    {
        key: "group",
        dataIndex: "userGroup",
        title: "User Group",
    },
];

export const USER_ROLE_COLUMN = [
    {
        key: "id",
        dataIndex: "id",
        title: "Subscriber Id",
    },
    {
        key: "name",
        dataIndex: "name",
        title: "Subscriber Name",
    },
];

export const USER_GROUP_COLUMN = [
    {
        key: "id",
        dataIndex: "id",
        title: "User Group Id",
    },
    {
        key: "name",
        dataIndex: "name",
        title: "User Group Name",
    },
];

export const CERTIFICATE_TYPE = [
    {
        id: 0,
        name: "Gold",
        value: "gold"
    },
    {
        id: 1,
        name: "Silver",
        value: "silver"
    },
]