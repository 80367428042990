import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { audit, auditBaseUrl } from '../endpoints';
// Define a service using a base URL and expected endpoints
  export const organizationMasterService = createApi({
    reducerPath: 'organizationMasterService',
    baseQuery: fetchBaseQuery({ baseUrl: auditBaseUrl }),
    tagTypes: ['Organization'],
    endpoints: (builder) => ({
        getAllOrganizationMaster: builder.mutation({
            query(body) {
                return {
                    url: `${audit.organizationMasterController.getAllOrganizationMaster}/${body.payload.page}/${body.payload.size}?orderBy=${body.payload.orderBy}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
        getOrganizationMasterById: builder.mutation({
            query(body) {
                return {
                    url: `${audit.organizationMasterController.getOrganizationMasterById}/${body.id}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
        saveOrganizationMaster: builder.mutation({
            query(body){
                return {
                    url: `${audit.organizationMasterController.saveOrganizationMaster}`,
                    method: 'POST',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        updateOrganizationMaster: builder.mutation({
            query(body){
                return {
                    url: `${audit.organizationMasterController.updateOrganizationMaster}/${body.id}`,
                    method: 'PUT',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        deleteOrganizationMaster: builder.mutation({
            query(body){
                return {
                    url: `${audit.organizationMasterController.deleteOrganizationMaster}/${body.id}`,
                    method: 'DELETE',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
        getAllOrganizationMasterBySearch: builder.mutation({
            query(body) {
                return {
                    url: `${audit.organizationMasterController.getAllOrganizationMasterBySearch}`,
                    method: "POST",
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                };
            },
        }),
        findAllOrganizationMaster: builder.mutation({
            query(body) {
                return {
                    url: `${audit.organizationMasterController.findAllOrganizationMaster}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
    })
  });
  export const {
    useGetAllOrganizationMasterMutation,
    useGetOrganizationMasterByIdMutation,
    useSaveOrganizationMasterMutation,
    useUpdateOrganizationMasterMutation,
    useDeleteOrganizationMasterMutation,
    useGetAllOrganizationMasterBySearchMutation,
    useFindAllOrganizationMasterMutation,
  } = organizationMasterService;