import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { auditBaseUrl, audit } from "../endpoints";
// Define a service using a base URL and expected endpoints
export const pdfService = createApi({
  reducerPath: "pdfService",
  baseQuery: fetchBaseQuery({ baseUrl: auditBaseUrl }),
  tagTypes: ["Pdf"],
  endpoints: (builder) => ({
    generatePdf: builder.mutation({
      query(body) {
        return {
          url: `${audit.generatePdfContorller.generatePdf}`,
          method: "POST",
          // headers: { Authorization: `Bearer ${body.token}`},
          body: body.payload,
        };
      },
    }),
    generateAuditDocument: builder.mutation({
      query(body) {
        return {
          url: `${audit.generatePdfContorller.generateAuditDocument}?auditId=${body.auditId}&type=${body.type}`,
          method: "POST",
          headers: { Authorization: `Bearer ${body.token}` },
          // body: body.payload,
          // responseHandler: (response) => response.blob(),
          responseHandler: ((response: any) => response.blob()
            .then((blob: any) => {
              const fileName = "Audit Details";
              const fileFormat = body.type;
              // 2. Create blob link to download
              const url = window.URL.createObjectURL(new Blob([blob]));
              const link: any = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${fileName}.${fileFormat}`);
              // 3. Append to html page
              document.body.appendChild(link);
              // 4. Force download
              link.click();
              // 5. Clean up and remove the link
              link.parentNode.removeChild(link);
            })),
        };
      },
    }),
    generateCertificate: builder.mutation({
      query(body) {
        return {
          url: `${audit.excelController.downloadCertificate}?auditId=${body.auditId}&type=${body.type}`,
          method: "POST",
          headers: { Authorization: `Bearer ${body.token}` },
          // body: body.payload,
          // responseHandler: (response) => response.blob(),
          responseHandler: ((response: any) => response.blob()
            .then((blob: any) => {
              const fileName = "Audit Certificate";
              const fileFormat = body.type;
              // 2. Create blob link to download
              const url = window.URL.createObjectURL(new Blob([blob]));
              const link: any = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${fileName}.xlsx`);
              // 3. Append to html page
              document.body.appendChild(link);
              // 4. Force download
              link.click();
              // 5. Clean up and remove the link
              link.parentNode.removeChild(link);
            })),
        };
      },
    }),
  }),
});

export const {
  useGeneratePdfMutation,
  useGenerateAuditDocumentMutation,
  useGenerateCertificateMutation,
} = pdfService;